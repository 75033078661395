@font-face {
  font-family: 'jakarta';
  src: url(../Assets/fonts/Jakarta/PlusJakartaSans-Regular.woff);
}

@font-face {
  font-family: 'jakarta';
  src: url(../Assets/fonts/Jakarta/PlusJakartaSans-Bold.woff);
  font-weight: 700;
}

@font-face {
  font-family: 'jakarta';
  src: url(../Assets/fonts/Jakarta/PlusJakartaSans-BoldItalic.woff);
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'jakarta';
  src: url(../Assets/fonts/Jakarta/PlusJakartaSans-ExtraBold.woff);
  font-weight: 800;
}

@font-face {
  font-family: 'jakarta';
  src: url(../Assets/fonts/Jakarta/PlusJakartaSans-ExtraBoldItalic.woff);
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: 'jakarta';
  src: url(../Assets/fonts/Jakarta/PlusJakartaSans-ExtraLight.woff);
  font-weight: 200;
}

@font-face {
  font-family: 'jakarta';
  src: url(../Assets/fonts/Jakarta/PlusJakartaSans-ExtraLightItalic.woff);
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'jakarta';
  src: url(../Assets/fonts/Jakarta/PlusJakartaSans-Italic.woff);
  font-style: italic;
}

@font-face {
  font-family: 'jakarta';
  src: url(../Assets/fonts/Jakarta/PlusJakartaSans-Light.woff);
  font-weight: 300;
}

@font-face {
  font-family: 'jakarta';
  src: url(../Assets/fonts/Jakarta/PlusJakartaSans-LightItalic.woff);
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'jakarta';
  src: url(../Assets/fonts/Jakarta/PlusJakartaSans-Medium.woff);
  font-weight: 500;
}

@font-face {
  font-family: 'jakarta';
  src: url(../Assets/fonts/Jakarta/PlusJakartaSans-MediumItalic.woff);
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Azeret';
  src: url(../Assets/fonts/Azeret/AzeretMono-Regular.ttf);
}

@font-face {
  font-family: 'Azeret';
  src: url(../Assets/fonts/Azeret/AzeretMono-Bold.ttf);
  font-weight: 700;
}

@font-face {
  font-family: 'Azeret';
  src: url(../Assets/fonts/Azeret/AzeretMono-BoldItalic.ttf);
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'Azeret';
  src: url(../Assets/fonts/Azeret/AzeretMono-ExtraBold.ttf);
  font-weight: 800;
}

@font-face {
  font-family: 'Azeret';
  src: url(../Assets/fonts/Azeret/AzeretMono-ExtraBoldItalic.ttf);
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: 'Azeret';
  src: url(../Assets/fonts/Azeret/AzeretMono-ExtraLight.ttf);
  font-weight: 200;
}

@font-face {
  font-family: 'Azeret';
  src: url(../Assets/fonts/Azeret/AzeretMono-ExtraLightItalic.ttf);
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'Azeret';
  src: url(../Assets/fonts/Azeret/AzeretMono-Italic.ttf);
  font-style: italic;
}

@font-face {
  font-family: 'Azeret';
  src: url(../Assets/fonts/Azeret/AzeretMono-Light.ttf);
  font-weight: 300;
}

@font-face {
  font-family: 'Azeret';
  src: url(../Assets/fonts/Azeret/AzeretMono-LightItalic.ttf);
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Azeret';
  src: url(../Assets/fonts/Azeret/AzeretMono-Medium.ttf);
  font-weight: 500;
}

@font-face {
  font-family: 'Azeret';
  src: url(../Assets/fonts/Azeret/AzeretMono-MediumItalic.ttf);
  font-weight: 500;
  font-style: italic;
}
